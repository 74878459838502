import React from 'react';
import { Card, Col, Row, Button, Text } from '@nextui-org/react';
import { openLink } from '../../../utility';

const MyCard = ({ title, linkShopee, price, imgLink }) => (
    <Card isPressable isHoverable variant='bordered' css={{ mw: '300px', opacity: 0.8 }} onClick={() => openLink(linkShopee, true)}>
        <Card.Body css={{ p: 0 }}>
            <Card.Image
                src={imgLink}
                width='300px'
                height='400px'
                alt={title}
            />
        </Card.Body>
        <Card.Footer
            isBlurred
            css={{
                width: 'auto',
                bgBlur: '#ffffff66',
                borderTop: '$borderWeights$light solid rgba(255, 255, 255, 0.2)',
            }}
        >
            <Row align='center' justify='space-between'>
                <Col>
                    <Row justify='flex-start' css={{ overflow: 'hidden' }}>
                        <Text b color='#000' size={16}>
                            {title}
                        </Text>
                    </Row>
                    <Row justify='flex-start'>
                        <Text color='#000' size={16} css={{ color: '$accents7', fontWeight: '$semibold', fontSize: '$sm' }}>
                            {price} VND
                        </Text>
                    </Row>
                </Col>
                <Col css={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Button flat auto rounded color='secondary' onClick={() => openLink(linkShopee, true)}>
                        <Text
                            css={{ color: 'inherit' }}
                            size={12}
                            weight='bold'
                            transform='uppercase'
                        >
                            Mua ngay
                        </Text>
                    </Button>
                </Col>
            </Row>
        </Card.Footer>
    </Card>
)


export default MyCard;