import './LandingMain.css';

import React, { useState, useEffect } from 'react'
import { Container, Text } from '@nextui-org/react';
import { cardGenerator } from '../../utility';

const LandingMain = () => {

    // const [cards, setCards] = useState([
    //     { 'PK': 1, 'title': 'Pokemon ABC1', 'linkShopee': 'https://google.com', 'price': 5000, 'imgLink': 'https://www.cardrush-pokemon.jp/data/cardrushpokemon/_/70726f647563742f4352535631615f5341525f312e6a7067003430300000660023666666666666.jpg' },
    //     { 'PK': 2, 'title': 'Pokemon ABC2', 'linkShopee': 'https://google.com', 'price': 6000, 'imgLink': 'https://www.cardrush-pokemon.jp/data/cardrushpokemon/_/70726f647563742f4352535631615f5341525f312e6a7067003430300000660023666666666666.jpg' },
    //     { 'PK': 3, 'title': 'Pokemon ABC3', 'linkShopee': 'https://google.com', 'price': 7000, 'imgLink': 'https://www.cardrush-pokemon.jp/data/cardrushpokemon/_/70726f647563742f53503131342e6a7067003430300000660023666666666666.jpg' },
    //     { 'PK': 4, 'title': 'Pokemon ABC4', 'linkShopee': 'https://google.com', 'price': 8000, 'imgLink': 'https://www.cardrush-pokemon.jp/data/cardrushpokemon/_/70726f647563742f53503131342e6a7067003430300000660023666666666666.jpg' }
    // ])

    const [cards, setCards] = useState([])

    useEffect(() => {
        fetch("https://api.pokeelite.com/item/hot-item").then((res) => res.json().then(data => setCards(cards => data)));
    }, [])

    return (
        <Container className='App-main' display='flex' direction='column' wrap='nowrap' >
            <Container className='Feature-product-text-container' display='flex' direction='column' justify='center' alignItems='center' >
                <Text className='Feature-product-text' h1 size={40}
                    css={{
                        textGradient: '45deg, #004892 -20%, #1871D2 50%',
                    }}
                    weight='bold'>Feature Product</Text>
            </Container>
            <Container className='Feature-product-container' display='flex' justify='space-evenly' alignItems='center' >
                {
                    cards.map(cardGenerator)
                }
            </Container>
        </Container>
    )
}

export default LandingMain;