import React from 'react';
import Header from './component/Header/Header';
import LandingMain from './component/LandingMain/LandingMain';
import Footer from './component/Footer/Footer';
import './App.css';

function App() {
  return (
    <div className='App'>
      <Header />
      <LandingMain />
      <Footer />
    </div>
  );
}

export default App;
