import './FooterButton.css'
import React from 'react';
import { Container, Button, Text } from '@nextui-org/react';
import { openLink } from '../../../utility';

const FooterButton = ({ text, link, onNewTab }) => (
    <Container className='Footer-button-container' display='flex' justify='center' alignItems='center'>
        <Button className='Footer-button' flat onClick={() => openLink(link, onNewTab)}>
            <Text
                css={{ color: 'aliceblue' }}
                size={16}
                weight='bold'
                transform='uppercase'
            >
                {text}
            </Text>
        </Button>
    </Container>
)


export default FooterButton;