import React from 'react';
import './Footer.css';

import { Container } from '@nextui-org/react';
import FooterButton from './FooterButton/FooterButton';

const linkFanPage = 'https://www.facebook.com/profile.php?id=100091812962109';
const linkShopee = 'https://shopee.vn/pokeelitestore';

const Footer = () => (
    <Container className='App-footer' display='flex' justify='center' >
        <FooterButton text={'Facebook'} link={linkFanPage} onNewTab={true}  />
        {/* TODO: Thêm link cửa hàng */}
        <FooterButton text={'Tham quan cửa hàng'} link={linkShopee} onNewTab={false}  />
        <FooterButton text={'Shopee'} link={linkShopee} onNewTab={true}  />
    </Container>
)

export default Footer;