import './Header.css'
import React from 'react';
import { Container, Image } from '@nextui-org/react';
import { openLink } from '../../utility';
import logo from '../../asset/NewLogo.svg';

const Header = () => (
  <Container className='App-header' display='flex' justify='center' css={{'paddingLeft': '0px', 'paddingRight': '0px'}}>
    <Image src={logo} alt='logo' className='Logo' width={300} onClick={() => openLink("https://pokeelite.com", false)}/>
  </Container>
)

export default Header;